window.Zretel or= {}

class window.Zretel.RowClickability

  @bindEvents: () ->
    # $('tbody').children('tr').each ->
    #   if $(this).find('.detail_link').length == 1
    #     $(this).css 'cursor', 'pointer'

    # $(document).on 'click', 'tr', {}, (event) ->
    #   if $(this).find('.detail_link').length == 1
    #     $(location).attr 'href', $(this).find('.detail_link').attr('href')
