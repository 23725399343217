import ahoy from "ahoy.js";

class CookieBar {
  constructor() {
    window.dataLayer = window.dataLayer || [];
    this.cookiesBar = document.getElementById('cookies-bar');
  }

  init() {
    function gtag(){dataLayer.push(arguments);}

    if (this.cookiesAllowed()) {
      this.appendGACode();
      this.hideCookieBar();
    }
    else {
      gtag('consent', 'default', {
        'analytics_storage': 'denied',
        'personalization_storage': 'denied',
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied'
      });
      this.showCookieBar()
    }

    this.addButtonBehaviors();
  }

  addButtonBehaviors() {
    if (!this.cookiesBar) {
      return;
    }

    document.querySelector('.accept-cookies-all').addEventListener('click', () => this.allowAll());
    document.querySelector('.bar-accept-cookies-all').addEventListener('click', () => this.allowAll());
    document.querySelector('.reject-cookies-all').addEventListener('click', () => this.rejectAll());
    document.querySelector('.accept-cookies-partialy').addEventListener('click', () => this.allowPartialy());
  }

  cookiesAllowed() {
    // console.log(document.cookie.split(';'))
    return Cookies.get('allow_cookies') === 'yes';
  }

  appendGACode() {
    const ga = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){" +
        "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o)," +
        "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)" +
        "})(window,document,'script','//www.google-analytics.com/analytics.js','ga');" +
        "ga('create', ' AW-957147084', 'auto');" + "ga('send', 'pageview');";

    document.getElementsByTagName('head')[0].append('<script>' + ga + '</script>');
  }

  rejectAll() {
    this.updateGtag('denied', 'denied', 'denied', 'denied', 'denied')

    $.ajax({
      type: "POST",
      url: "/save_cookies",
      data: {
        'analytics_storage': 'denied',
        'personalization_storage': 'denied',
        'ad_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied'
      }})
  }

  allowAll() {
    this.updateGtag('granted', 'granted', 'granted', 'granted', 'granted')

    $.ajax({
      type: "POST",
      url: "/save_cookies",
      data: {
        'analytics_storage': 'granted',
        'personalization_storage': 'granted',
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted'
      }})
  }

  allowPartialy() {
    let analytics = document.getElementById('cookie_settings_analytics_storage').checked ? 'granted' : 'denied';
    let personalization = document.getElementById('cookie_settings_personalization_storage').checked ? 'granted' : 'denied';
    let ad = document.getElementById('cookie_settings_ad_storage').checked ? 'granted' : 'denied';
    let ad_user_data = document.getElementById('cookie_settings_ad_user_data').checked ? 'granted' : 'denied';
    let ad_personalization = document.getElementById('cookie_settings_ad_personalization').checked ? 'granted' : 'denied';
    this.updateGtag(analytics, personalization, ad, ad_user_data, ad_personalization)

    $.ajax({
      type: "POST",
      url: "/save_cookies",
      data: {
        'analytics_storage': analytics,
        'personalization_storage': personalization,
        'ad_storage': ad,
        'ad_user_data': ad_user_data,
        'ad_personalization': ad_personalization
      }})
  }

  allowCookies(allow) {
    if (allow) {
      Cookies.set('allow_cookies', 'yes', {
        expires: 7
      });

    } else {
      Cookies.set('allow_cookies', 'no', {
        expires: 7
      });
    }

    this.hideCookieBar()
  }

  updateGtag(analytics, personalization, ad, ad_user_data, ad_personalization) {
    function gtag(){dataLayer.push(arguments);}

    gtag('consent', 'update', {
      'analytics_storage': analytics,
      'personalization_storage': personalization,
      'ad_storage': ad,
      'ad_user_data': ad_user_data,
      'ad_personalization': ad_personalization
    });

    ahoy.track("cookiebar_click", 'true');

    this.allowCookies(true);
  }

  hideCookieBar() {
    // this.cookiesBar.classList.add('hidden');
    // document.getElementById('hidden-cookies').classList.remove('hidden')
  }

  showCookieBar() {
    if (document.getElementById('cookies-init-modal')) {
      document.getElementById('cookies-init-modal').click()
    }
  }
}

// document.addEventListener("DOMContentLoaded", function() {
$(document).on('turbolinks:load', function() {
  const cookieBar = new CookieBar();
  cookieBar.init();
});
