

window.Zretel or= {}

class window.Zretel.User

  @formEvents: () ->
    $('.create-topic-link').on 'click', (event) ->
      event.preventDefault()
      topic = $(this).siblings('input')
      window.Zretel.User.createNewTopic(topic.val(), topic.attr('group'), topic.attr('area'), $('#yield').attr('current-user-id'))


  @createNewTopic: (name, group, area, user_id) ->
    $.ajax
      url: '/users/' + user_id + '/create_topic'
      method: 'POST'
      data:
        name: name
        group: group
        area: area
