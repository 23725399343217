
window.Zretel or= {}

class window.Zretel.Fault

  @setSolutionTokenfield: () ->
    $('#solutions-tokenfield').tokenfield()

    $('#solutions-tokenfield').on 'tokenfield:createtoken', (event) ->
      existingTokens = $(this).tokenfield('getTokens')
      $.each existingTokens, (index, token) ->
        if token.value == event.attrs.value
          event.preventDefault()
        return
      return