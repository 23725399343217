

// import './royal.scss';
// import './plugins/jquery/jquery-1.11.1.min.js';
// import './plugins/bootstrap/js/bootstrap.min.js';
// import './plugins/flexslider/jquery.flexslider.js';
// // import './plugins/selectbox/jquery.selectbox-0.1.3.min.js';
// // import './plugins/pop-up/jquery.magnific-popup.js';
// // import './plugins/animation/waypoints.min.js';
// import './plugins/count-up/jquery.counterup.js';
// import './plugins/animation/wow.min.js';
// // import './plugins/animation/moment.min.js';
// import './plugins/calender/fullcalendar.min.js';
// import './plugins/rs-plugin/js/jquery.themepunch.revolution.js';
// // import './plugins/owl-carousel/owl.carousel.js';
// import './plugins/timer/jquery.syotimer.js';
// // import './plugins/smoothscroll/SmoothScroll.js';

// import './custom.js';

$(window).on('load', function(){
      $('.body-wrapper').each(function(){
        var header_area = $('.header-wrapper');
        var main_area = header_area.children('.header');

        var logo = main_area.find('.navbar-header');
        var navigation = main_area.find('.navbar-collapse');
        var original = {
          nav_top: navigation.css('margin-top')
        };

        // $(window).scroll(function(){
        //   if( main_area.hasClass('bb-fixed-header') && ($(this).scrollTop() === 0 || $(this).width() < 750)){
        //     main_area.removeClass('bb-fixed-header').appendTo(header_area);
        //     navigation.animate({'margin-top': original.nav_top}, {duration: 300, queue: false, complete: function(){
        //       header_area.css('height', 'auto');
        //     }});
        //   }else if( !main_area.hasClass('bb-fixed-header') && $(this).width() > 750 &&
        //     $(this).scrollTop() > header_area.offset().top + header_area.height() - parseInt($('html').css('margin-top'),10) ){
        //
        //     header_area.css('height', header_area.height());
        //     main_area.css({'opacity': '0'}).addClass('bb-fixed-header');
        //     main_area.appendTo($('body')).animate({'opacity': 1});
        //
        //     navigation.css({'margin-top': '0px'});
        //   }
        // });
      });

      $(window).trigger('resize');
      $(window).trigger('scroll');
  });

  $('.navbar a.dropdown-toggle').on('click', function(e) {
        var elmnt = $(this).parent().parent();
        if (!elmnt.hasClass('nav')) {
            var li = $(this).parent();
            var heightParent = parseInt(elmnt.css('height').replace('px', ''),10) / 2;
            var widthParent = parseInt(elmnt.css('width').replace('px', ''),10) - 10;

            if(!li.hasClass('open')){li.addClass('open');}
            else{ li.removeClass('open');}
            $(this).next().css('top', heightParent + 'px');
            $(this).next().css('left', widthParent + 'px');

            return false;
        }
    });
