


window.Zretel or= {}

class window.Zretel.BirthDate

  @bindEvents: (id)->
    date = document.getElementById(id)
    if date
      date_suffix = date.closest('.birth-date-wrapper').getElementsByClassName('birth-date-suffix')[0]
      suffix_text = 'dd.mm.rrrr'


      checkValue = (str, max) ->
        if str.charAt(0) != '0' or str == '00'
          num = parseInt(str)
          if isNaN(num) or num <= 0 or num > max
            num = 1
          str = if num > parseInt(max.toString().charAt(0)) and num.toString().length == 1 then '0' + num else num.toString()
        str

      date.addEventListener 'input', (e) ->
        @type = 'text'
        input = @value
        if /\D\/$/.test(input)
          input = input.substr(0, input.length - 3)
        values = input.split('.').map((v) ->
          v.replace /\D/g, ''
        )
        if values[0]
          values[0] = checkValue(values[0], 31)
        if values[1]
          values[1] = checkValue(values[1], 12)
        output = values.map((v, i) ->
          if v.length == 2 and i < 2 then v + '.' else v
        )
        @value = output.join('').substr(0, 14)

        updateSuffix()
        return


      updateSuffix = ->
        width = getTextWidth(date.value, "16px Open Sans")
        date_suffix.innerHTML = suffix_text.slice(suffix_text.length - (suffix_text.length - date.value.length))
        date_suffix.style.left = width + 'px'
        return

      getTextWidth = (text, font) ->
        # re-use canvas object for better performance
        canvas = document.createElement("canvas");
        context = canvas.getContext("2d");
        context.font = font;
        width = context.measureText(text).width;
        formattedWidth = Math.ceil(width) + 23;
