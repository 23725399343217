import $ from "jquery";

$(function() {
  $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
  $('[data-toggle="popover"]').popover();
});

document.addEventListener('turbolinks:before-cache', function() {
  $('select').selectpicker('destroy');
});
